<template>
   <!-- 底部区域 -->
   <div class="footer">
      <div class="wrapper">
        <div class="help">
          <div class="left">
            <dl>
              <dt>关于我们</dt>
              <dd><a href="index.html">企业简介</a></dd>
              <dd><a href="about.html">团队介绍</a></dd>
              <dd><a href="more.html">产品优势</a></dd>
            </dl>
            <dl>
              <dt>产品和解决方案</dt>
              <dd><a href="#">建筑类光伏幕墙</a></dd>
              <dd><a href="#">新能源光伏玻璃</a></dd>
              <dd><a href="#">消费类光伏组件</a></dd>
            </dl>
            <dl>
              <dt>新闻中心</dt>
              <dd><a href="#">视频新闻</a></dd>
              <dd><a href="news.html">公司新闻</a></dd>
              <dd><a href="#">行业资讯</a></dd>
            </dl>
            <dl>
              <dt>购物指南</dt>
              <dd><a href="http://e.tb.cn/h.gDxG1RH7WCoGucy?tk=YH2N3NWCXIE HU0854 ">淘宝链接</a></dd>
              <!-- <dd><a href="#">购物流程</a></dd>
            <dd><a href="#">购物流程</a></dd> -->
            </dl>
            <dl>
              <dt>联系我们</dt>
              <dd><a href="#">在线客服<span class="iconfont icon-customer-service"></span></a></dd>
              <dd><a href="#">客服电话 400-0000-000</a></dd>
              <dd><a href="#">工作时间 周一至周五 8:30-17:30</a></dd>
            </dl>
          </div>
          <div class="right">
            <ul>
              <li>
                <img src="@/assets/images/wechat.png" alt="wechat">
                <p>微信公众号</p>
              </li>
              <li>
                <img src="@/assets/images/淘宝.jpg" alt="app">
                <p>淘宝店铺</p>
              </li>
            </ul>
          </div>
        </div>
        <!-- 版权 -->
        <!-- p嵌套a，到了网页底部不需要管引擎优化
       竖线直接用|不用border在a标签外面打 -->
        <div class="copyright">
          <p>
            <!-- <a href="#">关于我们</a>|
          <a href="#">关于我们</a>|
          <a href="#">关于我们</a>|
          <a href="#">关于我们</a>|
          <a href="#">关于我们</a>|
          <a href="#">关于我们</a>|
          <a href="#">关于我们</a> -->
            <a href="https://beian.miit.gov.cn">鄂ICP备2023009409号-1</a>
          </p>
          <p>CopyRight © 武汉华鸣新能源有限公司</p>
        </div>
      </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
/* 页脚 */
/* 底部区域 */
.footer {
  height: 400px;
  background-color: #fff;
}

/* 帮助 */
.footer .help {
  /* 方便后序加文字，直接给到最大高度 */
  display: flex;
  justify-content: space-between;
  padding-top: 60px;
  height: 300px;
  /* background-color: pink; */
}

.help .left {
  display: flex;
}

.help .left dl {
  margin-right: 84px;
}

.help .left dl:last-child {
  /* 将最后一个右间距去除，已经用了主轴对齐方式 */
  margin-right: 0;
}

.help .left dt {
  margin-bottom: 30px;
  font-size: 18px;
  color: #333;
}

.help .left dd {
  margin-bottom: 10.5px;
}

.help .left a {
  color: #333;
}

.help .left a .iconfont {
  margin-left: 5px;
  font-size: 16px;
  color: #6bbfae;
}

.help .right ul {
  display: flex;
}

.help .right ul li:first-child {
  margin-right: 55px;
}

.help .right ul li {
  text-align: center;
  color: #333;
}

.help .right ul img {
  width: 120px;
  height: 120px;
  margin-bottom: 10px;
}

/* 版权 */
.copyright p {
  margin-bottom: 10px;
}

.copyright p,
.copyright a {
  text-align: center;
  color: #333;
}

.copyright a {
  margin: 0 10px;
}

.copyright p a {
  text-decoration: underline;
  color: #333;
}
</style>
