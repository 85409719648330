<template>
  <el-carousel :interval="4000" type="card" lazy-load>
    <el-carousel-item v-for="item in src" :key="item.id">
      <!-- <h3 >{{ item.href }}</h3> -->
      <img :src="item.href" alt="" class="medium" />
    </el-carousel-item>
  </el-carousel>
</template>

<script>
export default {
  data () {
    return {
      isAndroid: 'false'
    }
  },
  props: {
    src: Array
  }
}
</script>

<style lang="less">
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  margin: 0;
}

.el-carousel__container {
  margin-top: 80px;
}

@media (max-width: 768px) {
  .el-carousel__container {
    height: 250px !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .el-carousel__container {
    height: 400px !important;
  }
}
@media (min-width: 992px) {
  .el-carousel__container {
    height: 600px !important;
  }
}
</style>
